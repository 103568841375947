import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { Container } from 'react-bootstrap';

const FraxCRDisplayEquation = () => {
    const [fraxData, setFraxData] = useState({
        totalAssets: 0,
        totalLiabilities: 0,
        lockedLiquidity: 0,
        CR: 0, // Adding CR to state for graph data update
    });
    const [graphData, setGraphData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch('https://api.frax.finance/v2/frax/balance-sheet/latest');
            const jsonData = await response.json();
            const totalLiabilities = jsonData.totalLiabilities;
            const lockedLiquidity = jsonData.totalLockedLiquidity;
            const totalAssets = jsonData.totalAssets + lockedLiquidity;
            const CR = ((totalAssets - lockedLiquidity) / totalLiabilities) * 100;
            const today = new Date().toLocaleDateString('en-US');

            setFraxData({ totalAssets, totalLiabilities, lockedLiquidity, CR });

            // Update graph data with the latest CR
            setGraphData({
              labels: [
                "2023-11-14", "2023-12-11", "2024-01-10", "2024-02-10",
                "2024-02-14", "2024-02-15", "2024-02-16", "2024-02-17", "2024-02-18", "2024-02-19",
                "2024-02-20", "2024-02-21", "2024-02-23", "2024-02-24", "2024-02-25", "2024-02-26",
                "2024-02-27", "2024-02-28", "2024-02-29", "2024-03-01", "2024-03-02", "2024-03-03",
                "2024-03-04", "2024-03-05", "2024-03-06", "2024-03-07", "2024-03-08", "2024-03-09", 
                "2024-03-10", "2024-03-11", "2024-03-12", "2024-03-13", "2024-03-14", "2024-03-15", today
              ],
                  
                datasets: [{
                    label: 'FRAX Collateralization Ratio (%)',
                    data: [
                      94.07, 94.98, 95.21, 95.83,
                      96.49, 96.50, 97.52, 96.39, 96.81, 96.96,
                      96.89, 97.00, 96.90, 97.28, 97.55, 97.63,
                      98.40, 98.46, 98.40, 98.40, 98.50, 98.40,
                      98.73, 98.89, 98.75, 98.79, 98.93, 98.81,
                      98.53, 98.84, 99, 99.8, 100.3, 99.3,
                        // Add all other CR values up to "98.46"
                        CR // Append the latest CR value
                    ],
                    fill: false,
                    backgroundColor: 'rgb(75, 192, 192)',
                    borderColor: 'rgba(75, 192, 192, 0.2)',
                    tension: 0.4
                }],
            });
        };

        fetchData();
    }, []);

    const options = {
        scales: {
            y: {
                beginAtZero: false,
                title: {
                    display: true,
                    text: 'Collateralization Ratio (%)',
                },
            },
            x: {
                type: 'time', // Specify the type as 'time'
                time: {
                  unit: 'month', // or 'day' depending on the level of granularity you prefer
                  tooltipFormat: 'MMM DD, YYYY', // Format for the tooltip
                  displayFormats: {
                    month: 'MMM YYYY', // Format to display the months on the x-axis
                  },
                },
                title: {
                    display: true,
                    text: 'Date',
                },
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
        },
        maintainAspectRatio: false,
    };

    return (
      <Container style={{ backgroundColor: '#1a1a1a', color: 'white', padding: '2rem', minHeight: '100vh', maxWidth: '100%' }}>
        <div style={{ backgroundColor: '#333', color: '#fff', padding: '20px', textAlign: 'center' }}>
            <h2>FRAX Collateralization Ratio (CR)</h2>
            <div>
                <strong>CR = (Total Assets - Locked Liquidity) / Total Liabilities</strong>
            </div>
            <div style={{ marginTop: '10px' }}>
                <strong>CR =</strong> (
                <span>${fraxData.totalAssets.toLocaleString('en-US', { maximumFractionDigits: 2 })}</span> - 
                <span>${fraxData.lockedLiquidity.toLocaleString('en-US', { maximumFractionDigits: 2 })}</span> ) / 
                <span>${fraxData.totalLiabilities.toLocaleString('en-US', { maximumFractionDigits: 2 })}</span>
            </div>
            <div style={{ marginTop: '20px', fontSize: '24px', fontWeight: 'bold' }}>
                CR = {fraxData.CR ? `${fraxData.CR.toFixed(2)}%` : 'Calculating...'}
            </div>
            <div style={{ height: '400px', marginTop: '50px' }}>
                {graphData.labels && <Line data={graphData} options={options} />}
            </div>
        </div>
      </Container>
    );
};

export default FraxCRDisplayEquation;
