import React, { useState, useEffect} from 'react';
import { Container, Row, Col, Card, Image, Button, Form } from 'react-bootstrap';

import axios from 'axios';
import FrxEthTotalGraph from './FrxEthTotalGraph';
import FrxEthGrowthGraph from './FrxEthGrowthGraph';
import FrxEthRewardsGraph from './FrxEthRewardsGraph';

const FraxFinanceDashboard = () => {
  const [frxEthData, setFrxEthData] = useState([]); // Initialize to an empty array
  const [userEthPrice, setUserEthPrice] = useState(null); // Initialize with null, to be set after data is loaded
  const [timeHorizon, setTimeHorizon] = useState(1); // Default to 1 year
  const [growthRate, setGrowthRate] = useState(0); // Default to 0% change

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = 'https://api.frax.finance/v2/frxeth/summary/history?range=all';
        const response = await axios.get(apiUrl);
        if (response.data && response.data.items) {
          const dataItems = response.data.items.reverse();
          setFrxEthData(dataItems);
          // Assuming EthPrice is part of the data fetched, set initial userEthPrice state
          const initialEthPrice = Math.round(dataItems[dataItems.length-1].ethPriceUsd); // Replace with actual path to EthPrice in your data
          setUserEthPrice(initialEthPrice);

          const initialThirtyDayMA = Math.round((dataItems[dataItems.length-1].frxethTotalSupply - dataItems[dataItems.length-31].frxethTotalSupply)/30);
          setGrowthRate(initialThirtyDayMA);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

    if (frxEthData.length === 0 || userEthPrice === null) {
      return <div>Loading...</div>;
    }

    const calculateProjectedTVL = (price, time, growth) => {
      const yearlyGrowth = growth * 365;
      const futureEthSupply = totalFrxEth + yearlyGrowth * time;
      return (futureEthSupply).toLocaleString(undefined, { maximumFractionDigits: 0 }) +" ETH or $" + (futureEthSupply*price).toLocaleString(undefined, { maximumFractionDigits: 0 });
    };

    const calculateProjectedRevenue = (price, time, growth) => {
      const yearlyGrowth = growth * 365;
      const futureEthSupply = totalFrxEth + yearlyGrowth * time;
      return ((futureEthSupply)/totalFrxEth * price * totalRewards * 365 * 0.08).toLocaleString(undefined, { maximumFractionDigits: 0 });
    };
    
    const latestData = frxEthData[frxEthData.length-1];
    const totalFrxEth = latestData.frxethTotalSupply; // Example, adjust based on actual data structure
    const EthPrice = latestData.ethPriceUsd;
    const totalFrxEthDollars = totalFrxEth * EthPrice;

    // Calculate 30-day MA growth of frxETH (This is a simplified example, adjust as needed)
    const thirtyDayMA = (totalFrxEth - frxEthData[frxEthData.length-31].frxethTotalSupply)/30;

    // Calculate total rewards of frxETH
    const totalRewards = ((latestData.cumulativeValidatorBeaconRewards + latestData.cumulativeValidatorTipsAndMev) 
        - (frxEthData[frxEthData.length-4].cumulativeValidatorBeaconRewards + frxEthData[frxEthData.length-4].cumulativeValidatorTipsAndMev))/3; // Adjust based on actual data

/*   return (
    <div style={{ background: 'black', color: 'white', padding: '10px' }}>
      <h1>Frax Finance frxETH Revenue Projections</h1>
      {frxEthData && (
                <>      <div className="summary">
                <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
                The total value locked in frxETH is <span style={{ fontSize: '36px', fontWeight: 'bold' }}>{totalFrxEth.toLocaleString(undefined, {maximumFractionDigits: 0})} ETH </span>
                or <span style={{ fontSize: '36px', fontWeight: 'bold' }}>${totalFrxEthDollars.toLocaleString(undefined, {maximumFractionDigits: 0})}</span>. 
                </p>
                <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
                It is growing at a rate of <span style={{ fontSize: '36px', fontWeight: 'bold' }}>{thirtyDayMA.toFixed(0)} ETH</span> per day.
                </p>
                <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
                Each day, frxETH generates <span style={{ fontSize: '36px', fontWeight: 'bold' }}>{totalRewards.toFixed(2)} ETH</span> in rewards.
                </p>
                <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
                Of which, Frax collects 8% as revenue: <span style={{ fontSize: '36px', fontWeight: 'bold' }}>{(totalRewards*.08).toFixed(2)} ETH </span>
                or <span style={{ fontSize: '36px', fontWeight: 'bold' }}>${(totalRewards*.08*EthPrice).toLocaleString(undefined, {maximumFractionDigits: 0})}</span> per day.
                </p>
                <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
                For yearly revenue of <span style={{ fontSize: '36px', fontWeight: 'bold' }}>{(totalRewards*365*.08).toFixed(2)} ETH </span>
                or <span style={{ fontSize: '36px', fontWeight: 'bold' }}>${(totalRewards*365*.08*EthPrice).toLocaleString(undefined, {maximumFractionDigits: 0})}</span>.
                </p>
                <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
                If frxETH were to continue to grow at its current pace and ETH price were to remain constant:
                </p>
                <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
                1 year from now, total frxETH would be <span style={{ fontSize: '36px', fontWeight: 'bold' }}>{(totalFrxEth+thirtyDayMA*365).toLocaleString(undefined, {maximumFractionDigits: 0})} ETH </span>
                or <span style={{ fontSize: '36px', fontWeight: 'bold' }}>${((totalFrxEth+thirtyDayMA*365)*EthPrice).toLocaleString(undefined, {maximumFractionDigits: 0})}</span>. 
                </p>
                <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
                Generating yearly revenue of <span style={{ fontSize: '36px', fontWeight: 'bold' }}>{((totalFrxEth+thirtyDayMA*365)/totalFrxEth*totalRewards*365*.08).toFixed(2)} ETH </span>
                or <span style={{ fontSize: '36px', fontWeight: 'bold' }}>${((totalFrxEth+thirtyDayMA*365)/totalFrxEth*totalRewards*365*.08*EthPrice).toLocaleString(undefined, {maximumFractionDigits: 0})}</span>.
                </p>
                <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
                If ETH price were to instead increase to $4000, that ETH would be worth <span style={{ fontSize: '36px', fontWeight: 'bold' }}>${((totalFrxEth+thirtyDayMA*365)*4000).toLocaleString(undefined, {maximumFractionDigits: 0})}</span>. 
                </p>
                <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
                Generating yearly revenue of <span style={{ fontSize: '36px', fontWeight: 'bold' }}>${((totalFrxEth+thirtyDayMA*365)/totalFrxEth*totalRewards*365*.08*4000).toLocaleString(undefined, {maximumFractionDigits: 0})}</span>.
                </p>
            </div>
          <div style={{ marginBottom: '20px' }}>
            <FrxEthTotalGraph data={frxEthData} />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <FrxEthGrowthGraph data={frxEthData} />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <FrxEthRewardsGraph data={frxEthData} />
          </div>
        </>
      )}
    </div>
  ); */
/*   return (
    <Container style={{ backgroundColor: '#1a1a1a', color: 'white', padding: '2rem', minHeight: '100vh', maxWidth: '100%' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '2rem' }}>Frax Finance frxETH Revenue Projections</h1>
      
      <Row className="justify-content-center">
        <Col xs={12} md={6} className="border-md-none" style={{ textAlign: 'center', borderRight: '1px solid grey', paddingRight: '2rem', paddingLeft: '2rem',  marginBottom: '2rem' }}>
          <h2>Total Value Locked</h2>
          <p style={{ fontSize: '1.5rem' }}>{totalFrxEth.toLocaleString(undefined, {maximumFractionDigits: 0})} ETH or ${totalFrxEthDollars.toLocaleString(undefined, {maximumFractionDigits: 0})}</p>
          
          <h2>30MA Daily Growth</h2>
          <p style={{ fontSize: '1.5rem' }}>{thirtyDayMA.toFixed(0)} ETH</p>
          
          <h2>Daily Rewards</h2>
          <p style={{ fontSize: '1.5rem' }}>{totalRewards.toFixed(2)} ETH</p>

          <h2>Frax Revenue</h2>
          <p style={{ fontSize: '1.5rem' }}>{(totalRewards*.08).toFixed(2)} ETH or ${(totalRewards*.08*EthPrice).toLocaleString(undefined, {maximumFractionDigits: 0})}</p>
        </Col>
        
        <Col xs={12} md={6} style={{ textAlign: 'center', paddingLeft: '2rem', paddingRight: '2rem' }}>
          <h2>Yearly Projections</h2>
          <p style={{ fontSize: '1.5rem' }}>Yearly revenue of {(totalRewards*365*.08).toFixed(2)} ETH or ${(totalRewards*365*.08*EthPrice).toLocaleString(undefined, {maximumFractionDigits: 0})}</p>
          
          <h2>Future Growth</h2>
          <p style={{ fontSize: '1.5rem' }}>
            If growth continues at the current pace, in 1 year total frxETH would be {(totalFrxEth+thirtyDayMA*365).toLocaleString(undefined, {maximumFractionDigits: 0})} ETH
                or ${((totalFrxEth+thirtyDayMA*365)*EthPrice).toLocaleString(undefined, {maximumFractionDigits: 0})}.
          </p>
          
          <h2>ETH Price Impact</h2>
          <p style={{ fontSize: '1.5rem' }}>
            If ETH price were to increase to $4000, the yearly revenue would be {((totalFrxEth+thirtyDayMA*365)/totalFrxEth*totalRewards*365*.08*4000).toLocaleString(undefined, {maximumFractionDigits: 0})}.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <div style={{ marginBottom: '20px', padding: '2rem' }}>
            <FrxEthTotalGraph data={frxEthData} />
        </div>
      </Row>
      <Row className="justify-content-center">
        <div style={{ marginBottom: '20px', padding: '2rem' }}>
          <FrxEthGrowthGraph data={frxEthData} />
        </div>
      </Row>
      <Row className="justify-content-center">
        <div style={{ marginBottom: '20px', padding: '2rem' }}>
          <FrxEthRewardsGraph data={frxEthData} />
        </div>
      </Row>
    </Container>
  ); */
  return (
    <Container style={{ backgroundColor: '#1a1a1a', color: 'white', padding: '2rem', minHeight: '100vh', maxWidth: '100%' }}>
      <Row className="mb-4 justify-content-center align-items-center" style={{ textAlign: 'center'}}>
          <Col md={12}>
              <h1>Frax Finance frxETH Revenue</h1>
              <img src="/frxETH-logo.png" alt="frxETH Logo" style={{maxWidth: '200px', width: '100%', height: 'auto', padding: '1rem', margin: '1rem' }} />
              <p>
                <Button variant="outline-light" href="https://facts.frax.finance/frxeth" target="_blank">
                  Learn More at FraxFacts
                </Button>
              </p>
          </Col>
      </Row>
      
      <Row className="justify-content-center mb-4">
        <Col xs={12} lg={6}>
          <Card style={{ textAlign: 'center', backgroundColor: '#2C2C2C', color: 'white', padding: '2.33rem', marginBottom: '2rem' }}>
            <Card.Title>Total Value Locked</Card.Title>
            <Card.Text>{totalFrxEth.toLocaleString(undefined, {maximumFractionDigits: 0})} ETH or ${totalFrxEthDollars.toLocaleString(undefined, {maximumFractionDigits: 0})}</Card.Text>
            <Card.Title> </Card.Title>
            <Card.Title>30MA Daily Growth</Card.Title>
            <Card.Text>{thirtyDayMA.toFixed(0)} ETH</Card.Text>
            <Card.Title> </Card.Title>
            <Card.Title>Daily Rewards</Card.Title>
            <Card.Text>{totalRewards.toFixed(2)} ETH</Card.Text>
            <Card.Title> </Card.Title>
            <Card.Title>Frax Daily Revenue</Card.Title>
            <Card.Text>{(totalRewards*.08).toFixed(2)} ETH or ${(totalRewards*.08*EthPrice).toLocaleString(undefined, {maximumFractionDigits: 0})}</Card.Text>
            <Card.Title> </Card.Title>
            <Card.Title>Yearly Projections</Card.Title>
            <Card.Text>Yearly revenue of {(totalRewards*365*.08).toFixed(2)} ETH or ${(totalRewards*365*.08*EthPrice).toLocaleString(undefined, {maximumFractionDigits: 0})}</Card.Text>
            <Card.Title> </Card.Title>
            <Card.Title> </Card.Title>
            <Card.Title>Future Growth and Revenue</Card.Title>
            <Card.Text>
              If growth is constant, in 1 year total frxETH will be {(totalFrxEth+thirtyDayMA*365).toLocaleString(undefined, {maximumFractionDigits: 0})} ETH or ${((totalFrxEth+thirtyDayMA*365)*EthPrice).toLocaleString(undefined, {maximumFractionDigits: 0})}.
            </Card.Text>
            <Card.Text>
              Yearly revenue would be {((totalFrxEth+thirtyDayMA*365)/totalFrxEth*totalRewards*365*.08).toFixed(0)} ETH or ${((totalFrxEth+thirtyDayMA*365)/totalFrxEth*totalRewards*365*.08*EthPrice).toLocaleString(undefined, {maximumFractionDigits: 0})} at current ETH price.
            </Card.Text>
          </Card>
        </Col>

        <Col xs={12} lg={6}>
          <Card style={{ textAlign: 'center', backgroundColor: '#2C2C2C', color: 'white', padding: '2rem', maxWidth: '100%' }}>

            <Card.Title>frxETH Revenue Projections</Card.Title>
            <Card style={{ backgroundColor: '#2C2C2C', color: 'white', padding: '2rem', marginBottom: '1rem'}}>
              <Form>
                <Row>
                  <Col xs={5} className="d-flex align-items-center">
                    <Form.Label htmlFor="formTimeHorizon" className="mb-0">Time Horizon (years):</Form.Label>
                  </Col>
                  <Col xs={7}>
                    <Form.Control
                      id="formTimeHorizon"
                      type="number"
                      value={timeHorizon}
                      onChange={(e) => setTimeHorizon(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form>
            </Card>
          <Card style={{ backgroundColor: '#2C2C2C', padding: '2rem', color: 'white', marginBottom: '1rem'}}>
            <Form>
              <Row>
                <Col xs={5} className="d-flex align-items-center">
                  <Form.Label htmlFor="formGrowthRate" className="mb-0">Daily Growth (ETH):</Form.Label>
                </Col>
                <Col xs={7}>
                  <Form.Control
                    id="formGrowthRate"
                    type="number"
                    value={growthRate}
                    onChange={(e) => setGrowthRate(e.target.value)}
                  />
                </Col>
              </Row>
            </Form>
          </Card>
          <Card style={{ backgroundColor: '#2C2C2C', padding: '2rem', color: 'white', marginBottom: '1rem'}}>
            <Form>
              <Row>
                <Col xs={5} className="d-flex align-items-center">
                  <Form.Label htmlFor="formEthPrice" className="mb-0">ETH Price ($):</Form.Label>
                </Col>
                <Col xs={7}>
                  <Form.Control
                    id="formEthPrice"
                    type="number"
                    value={userEthPrice}
                    onChange={(e) => setUserEthPrice(e.target.value)}
                  />
                </Col>
              </Row>
            </Form>
          </Card>
          <Card.Title>Projected TVL</Card.Title>
            <Card.Text>
              {calculateProjectedTVL(userEthPrice, timeHorizon, growthRate)} 
            </Card.Text>
            <Card.Title>Projected Yearly Revenue</Card.Title>
            <Card.Text>
              ${calculateProjectedRevenue(userEthPrice, timeHorizon, growthRate)}
            </Card.Text>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} style={{ padding: '0.5rem' }}>
          <FrxEthTotalGraph data={frxEthData} />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} style={{ padding: '0.5rem' }}>
          <FrxEthGrowthGraph data={frxEthData} />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} style={{ padding: '0.5rem' }}>
          <FrxEthRewardsGraph data={frxEthData} />
        </Col>
      </Row>
    </Container>
  );
};

export default FraxFinanceDashboard;
