import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { db } from './firebaseConfig'; // Ensure this path is correct
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import ParticlesBackground from './ParticlesBackground'; // Adjust the path as necessary


// Now you can use `db` to interact with Firestore

const Home = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false); // Track submission status

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(collection(db, 'emailSubmissions'), {
        email: email,
        timestamp: serverTimestamp(), // Use server timestamp
      });
      console.log('Submission successful');
      setEmail(''); // This should clear the input field
      setSubmitted(true); // Update submission status
      setTimeout(() => setSubmitted(false), 5000); // Reset after 5 seconds
    } catch (error) {
      console.error('Error writing document: ', error);
    }
  };

  return (
    <>
    <ParticlesBackground />
    <div style={{ position: 'relative', overflow: 'auto', height: '100vh' }}>
      <Container fluid style={{ zIndex: 2, position: 'relative', color: 'white', minHeight: '100vh' }}>
        {/* Header Section with Logo */}
        <Row className="align-items-center" style={{ minHeight: '80vh', padding: '1rem' }}>
          <Col md={8}>
            <h1 className="header-title">Welcome to SatSoft</h1>
            <p style={{ fontSize: '1.2rem', marginBottom: '25px' }}>
              We are building a platform to connect crypto projects with top creators.
            </p>
            <p style={{ fontSize: '1.2rem' }}>
              <strong>For Creators:</strong> Work with projects, get rewarded, gain recognition.
            </p>
            <p style={{ fontSize: '1.2rem' }}>
              <strong>For Projects:</strong> Spread quality content and cultivate your creators.
            </p>
            <p style={{ fontSize: '1.2rem', marginBottom: '5px' }}>
              <strong>Interested?</strong>
            </p>
            {/* Email Signup Form */}
            <Form onSubmit={handleSubmit}>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter email or handle"
                  aria-label="Enter email or handle"
                  aria-describedby="button-addon2"
                  style={{ maxWidth: '400px' }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={submitted} // Optional: Disable input when submitted
                />
                <Button variant="outline-secondary" type="submit" id="button-addon2">
                  {submitted ? 'Submitted!' : 'Submit'}
                </Button>
              </InputGroup>
            </Form>
            <br></br>
          </Col>
          <Col md={4} className="d-flex justify-content-center">
            <img src="/white_logo.png" alt="SatSoft Logo" style={{ maxWidth: '300px', width: '75%', height: 'auto' }} />
          </Col>
        </Row>
        
        {/* Footer */}
        <Row style={{ textAlign: 'center', marginTop: '2rem' }}>
          <Col>
            <p>&copy; {new Date().getFullYear()} SatSoft. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  );
};

export default Home;
