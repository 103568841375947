import React from 'react';
import { Line, Bar, Scatter } from 'react-chartjs-2';
import 'moment';
import 'chartjs-adapter-moment';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,       // Import TimeScale
    TimeSeriesScale  // Import TimeSeriesScale if necessary
  } from 'chart.js';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,       // Register TimeScale
    TimeSeriesScale  // Register TimeSeriesScale if you're using it
  );

const FrxEthGrowthGraph = ({ data }) => {
  // Calculate daily change in frxethTotalSupply
  const dailyChanges = data.map((item, index) => {
    if (index === 0) return 0;
    return item.frxethTotalSupply - data[index - 1].frxethTotalSupply;
  }).slice(1); // Exclude the first day as it has no previous day to compare

  // Calculate 30-day moving average
  const movingAverages = dailyChanges.map((_, index) => {
    if (index < 29) return null; // No moving average for the first 29 days
    const slice = dailyChanges.slice(index - 29, index + 1);
    const sum = slice.reduce((acc, val) => acc + val, 0);
    return sum / 30;
  });

  const chartData = {
    labels: data.map(item => new Date(item.intervalTimestamp).toLocaleDateString()).slice(1),
    datasets: [
      {
        label: 'Daily Change',
        data: dailyChanges.map((change, index) => ({
          x: data[index + 1].intervalTimestamp,
          y: change
        })),
        backgroundColor: 'rgba(255, 99, 132, 1)',
        pointRadius: 3,
        pointHoverRadius: 5,
        showLine: false, // No line for scatter
      },
      {
        label: '30-Day MA',
        data: movingAverages.map((avg, index) => ({
          x: data[index].intervalTimestamp,
          y: avg
        })),
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 2,
        showLine: true, // Line for moving average
        fill: false,
        pointRadius: 0, // No points for moving average line
      }
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: 'white',
        },
      },
      y: {
        max: 5000,
        min: 0,
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
            color: 'white',
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: 'white',
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleColor: 'white',
        bodyColor: 'white',
        borderColor: 'grey',
        borderWidth: 1,
        mode: 'index',
        intersect: false,
        callbacks: {
            label: function(context) {
            // Get the label of the dataset
            const datasetLabel = context.dataset.label;

            // Get the y-value
            const value = context.parsed.y;

            // Return the string to display in the tooltip
            return `${datasetLabel}: ${value.toFixed(2)} ETH`;;
            },
            title: function(context) {
              // Optionally, you can also customize the title of the tooltip
              const date = new Date(context[0].parsed.x);
              return date.toLocaleDateString();
            }
          },
      },
    },
    maintainAspectRatio: true,
    responsive: true,
  };

  return (
    <div>
      <h2 style={{ textAlign: 'center', color: 'white' }}>frxETH Growth</h2>
      <Scatter data={chartData} options={options} />
    </div>
  );
};

export default FrxEthGrowthGraph;
