// FrxEthRewardsGraph.js
import React from 'react';
import { Line, Bar, Scatter } from 'react-chartjs-2';
import 'moment';
import 'chartjs-adapter-moment';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,       // Import TimeScale
    TimeSeriesScale  // Import TimeSeriesScale if necessary
  } from 'chart.js';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,       // Register TimeScale
    TimeSeriesScale  // Register TimeSeriesScale if you're using it
  );

const FrxEthRewardsGraph = ({ data }) => {
  const labels = data.map(item => new Date(item.intervalTimestamp).toLocaleDateString());

  const dailyBeaconRewards = data.map((item, index) => 
    index === 0 ? 0 : item.cumulativeValidatorBeaconRewards - data[index - 1].cumulativeValidatorBeaconRewards
  );
  const dailyTipsAndMev = data.map((item, index) => 
    index === 0 ? 0 : item.cumulativeValidatorTipsAndMev - data[index - 1].cumulativeValidatorTipsAndMev
  );

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Daily Beacon Rewards',
        data: dailyBeaconRewards,
        backgroundColor: 'rgba(135, 206, 250, 0.6)', // Light blue
      },
      {
        label: 'Daily Tips and MEV',
        data: dailyTipsAndMev,
        backgroundColor: 'rgba(144, 238, 144, 0.6)', // Light green
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
        },
        stacked: true,
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: 'white',
        },
      },
      y: {
        max: 50,
        min: 0,
        stacked: true,
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: 'white',
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: 'white',
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleColor: 'white',
        bodyColor: 'white',
        borderColor: 'grey',
        borderWidth: 1,
        mode: 'index',
        intersect: false,
        callbacks: {
            title: function(context) {
                // Optionally, you can also customize the title of the tooltip
                const date = new Date(context[0].parsed.x);
                return date.toLocaleDateString();
              },
          footer: (tooltipItems) => {
            const sum = tooltipItems.reduce((acc, item) => acc + item.parsed.y, 0);
            return `Total: ${sum.toFixed(2)} ETH`;
          },
        },
      },
    },
    maintainAspectRatio: true,
    responsive: true,
  };

  return (
    <div>
      <h2 style={{ textAlign: 'center', color: 'white' }}>frxETH Rewards</h2>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default FrxEthRewardsGraph;
