// FrxEthTotalGraph.js
import React from 'react';
import { Line, Bar, Scatter } from 'react-chartjs-2';
import 'moment';
import 'chartjs-adapter-moment';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,       // Import TimeScale
    TimeSeriesScale,  // Import TimeSeriesScale if necessary
    Filler
  } from 'chart.js';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,       // Register TimeScale
    TimeSeriesScale,  // Register TimeSeriesScale if you're using it
    Filler
    );



const FrxEthTotalGraph = ({ data }) => {
  const labels = data.map(item => new Date(item.intervalTimestamp).toLocaleDateString());
  const totalSupply = data.map(item => item.frxethTotalSupply);

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Total frxETH',
        data: totalSupply,
        fill: true,
        pointRadius: 0,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192,0.2)',
        tension: 0.4,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: 'white',
        },
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: 'white',
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: 'white',
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleColor: 'white',
        bodyColor: 'white',
        borderColor: 'grey',
        borderWidth: 1,
        mode: 'index',
        intersect: false,
        callbacks: {
          title: function(context) {
            // Optionally, you can also customize the title of the tooltip
            const date = new Date(context[0].parsed.x);
            return date.toLocaleDateString();
          }
        },
      },
    },
    maintainAspectRatio: true,
    responsive: true,
  };

  return (
    <div>
      <h2 style={{ textAlign: 'center', color: 'white' }}>Total frxETH</h2>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default FrxEthTotalGraph;
