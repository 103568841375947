import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import Home from './Home';
import { BrowserRouter as Router, Routes, Route, Switch } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import FraxFinanceDashboard from './projects/FraxFinanceDashboard';
import Marketing from './projects/Marketing';
import Hardware from './projects/Hardware';
import FraxCR from './projects/FraxCR';


function App() {
  return (
    <Router>
      <div>
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/hardware" element={<Hardware />} />
          <Route path="/analytics/frxETH" element={<FraxFinanceDashboard />} />
          <Route path="/analytics/FraxCR" element={<FraxCR />} />
          <Route path="/projects/Marketing" element={<Marketing />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
