import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const Contact = () => {
    const [formData, setFormData] = useState({
        user_name: '',
        user_email: '',
        message: '',
    });
    
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_8k0n3ua', 'template_ct5s8hk', e.target, 'gjbEz1Az_sy40id9M')
        .then((result) => {
            console.log(result.text);
            // Handle success (e.g., showing a success message)
            setFormData({ user_name: '', user_email: '', message: '' }); // Reset form
        }, (error) => {
            console.log(error.text);
            // Handle errors here
        });
    };

    return (
    <Container fluid style={{ backgroundColor: '#1a1a1a', color: 'white', padding: '2rem', minHeight: '100vh' }}>
        <Row className="justify-content-center">
        <Col md={6}>
            <h2>Contact Us</h2>
            <Form onSubmit={sendEmail}>
            <Form.Group className="mb-3">
                <Form.Label>Your Name</Form.Label>
                <Form.Control 
                type="text" 
                name="user_name" 
                value={formData.user_name} 
                onChange={handleChange} 
                placeholder="Enter your name" 
                required 
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Your Email</Form.Label>
                <Form.Control 
                type="email" 
                name="user_email" 
                value={formData.user_email} 
                onChange={handleChange} 
                placeholder="Enter your email" 
                required 
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control 
                as="textarea" 
                rows={3} 
                name="message" 
                value={formData.message} 
                onChange={handleChange} 
                placeholder="Your message" 
                required 
                />
            </Form.Group>
            <Button variant="outline-light" type="submit">Send Message</Button>
            </Form>
        </Col>
        </Row>
    </Container>
    );
};

export default Contact;  