import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

const Hardware = () => {
  return (
    <Container fluid style={{ backgroundColor: '#1a1a1a', color: 'white', minHeight: '100vh', padding: '2rem', maxWidth: '100%'  }}>
      <Row className="justify-content-center">
        <Col md={10} lg={8}>
          <h2 className="text-center mb-4">Bitcoin Rollercoaster Price Displays</h2>

          <Row className="align-items-center">
            <Col md={6}>
              <p>
                Our Bitcoin rollercoaster price displays have captivated enthusiasts and collectors across the globe. 
                With over 150 units sold in more than 15 countries, these displays have become a unique and engaging way to track Bitcoin's dynamic price changes.
              </p>
              <p>
                Displays were designed and built in-house. Rollercoaster points up and down according to 24hr price change.
              </p>
            </Col>
            <Col md={6}>
              <Image src="/bitcoin-display.png" alt="Bitcoin Rollercoaster Display" fluid />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Hardware;
