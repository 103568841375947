import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';

const Marketing = () => {
  return (
    <Container fluid style={{ backgroundColor: '#1a1a1a', color: 'white', padding: '2rem', maxWidth: '100%'  }}>
      
        <Row className="mb-4 justify-content-center align-items-center" style={{ textAlign: 'center'}}>
            <Col md={12}>
                <h1>StableScarab</h1>
                <Button variant="outline-light" href="https://twitter.com/StableScarab" target="_blank">
                    Follow on Twitter
                </Button>
            </Col>
        </Row>
      
      {/* StableScarab Intro and Logo */}
      <Row className="mb-4 justify-content-center align-items-center">
        <Col md={8}>
          <h2>Microinfluencer with an Outsized Impact</h2>
          <p>
            Founder of StableScarab, focused on unique research that educates, not shills. Our mission is to make the crypto space accessible and informative.
          </p>
        </Col>
        <Col md={4}>
          <Image src="/stablescarab-logo.png" alt="StableScarab Logo" style={{maxWidth: '300px'}} fluid />
        </Col>
      </Row>

      {/* FraxFinance Case Study */}
      <Row className="mb-4 justify-content-center align-items-center">
        <Col md={8}>
          <h3>Frax Finance Education</h3>
          <p>
            Gained significant traction with in-depth coverage of Frax Finance, attracting a wide audience and establishing a strong presence in crypto education.
            <br />
                <a 
                href="https://flywheeldefi.com/article/how-frxeth-v2-balances-capital-efficiency" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ 
                    color: '#3f84e4', 
                    textDecoration: 'none',
                    borderBottom: '2px solid transparent',
                    transition: 'border-color 0.3s ease-in-out'
                }}
                onMouseOver={(e) => e.currentTarget.style.borderColor = '#3f84e4'}
                onMouseOut={(e) => e.currentTarget.style.borderColor = 'transparent'}
                >
                How frxETH v2 Balances Capital Efficiency
                </a>.
          </p>
        </Col>
        <Col md={4}>
          <Image src="/frax-content.png" alt="Frax Content" style={{maxWidth: '300px'}} fluid />
        </Col>
      </Row>

      {/* LSD Summer Series Case Study */}
      <Row className="mb-4 justify-content-center align-items-center">
        <Col md={8}>
          <h3>LSD Summer Series</h3>
          <p>
            A comprehensive data-packed series that brought light to the intricacies of LSD within the blockchain space, captivating a dedicated audience.
          </p>
        </Col>
        <Col md={4}>
          <Image src="/lsd-summer-series.png" alt="LSD Summer Series" style={{maxWidth: '300px'}} fluid />
        </Col>
      </Row>

      {/* ThreadooorDAO and Logo */}
      <Row className="mb-4 justify-content-center align-items-center">
        <Col md={8}>
          <h3>Founded ThreadooorDAO</h3>
          <p>
            A collective of threaders with a mission to learn and amplify the voices within the crypto community. Our work has broadened the horizon for educational content in crypto.
          </p>
        </Col>
        <Col md={4}>
          <Image src="/threadooordao-logo.png" alt="ThreadooorDAO Logo" style={{maxWidth: '300px'}} fluid />
        </Col>
      </Row>

    </Container>
  );
};

export default Marketing;

