import React from 'react';
import { Navbar, Nav, Container, Dropdown} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NavigationBar = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="global-navbar">
      <Container>
      <Navbar.Brand as={Link} to="/">
          <img
            src="/logo192.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="SatSoft Logo"
            style={{ marginRight: '10px' }}
          />
          SatSoft
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            
            <Dropdown>
              <Dropdown.Toggle variant="dark" id="dropdown-projects">
                Projects
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/projects/hardware">Hardware</Dropdown.Item>
                <Dropdown.Item as={Link} to="/projects/marketing">Marketing</Dropdown.Item>
                <Dropdown drop="end">
                  <Dropdown.Toggle as={Nav.Link} style={{ color: 'black', padding: '0.25rem 1rem' }}>Analytics</Dropdown.Toggle>
                  <Dropdown.Menu >
                    <Dropdown.Item href="/analytics/FraxCR">Frax CR</Dropdown.Item> 
                    <Dropdown.Item href="/analytics/frxETH">frxETH Revenue</Dropdown.Item>
                    <Dropdown.Item href="https://dune.com/stablescarab/sfrax">sFRAX Dune</Dropdown.Item>
                    <Dropdown.Item href="https://dune.com/stablescarab/wpokt">wPOKT Dune</Dropdown.Item>
                    {/* Add more analytics links as needed */}
                  </Dropdown.Menu>
                </Dropdown>
              </Dropdown.Menu>
            </Dropdown>

            <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
